// This is where we define custom CAMP variable

$font-family-sans-serif: 'Source Sans Pro', sans-serif !important;
$font-family-serif:      Georgia, "Times New Roman", Times, serif;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:       $font-family-sans-serif;

$camp-navy: #0E355A;
$camp-gold: #F9B719;
$camp-ink: #061726;
$camp-emerald: #00785B;
$camp-emerald-hover: #004D3A;
$camp-paper: #EFF2F4;
$camp-text-secondary: #595959;
$camp-text-tertiary: #757575;
$camp-neutral: #FBFBFB;
$camp-text: #0e0e0e;

// For emerald-on-dark color scheme
$camp-emerald-light: #00785B;
$camp-emerald-light-hover: #0EB88E;

$primary-color: $camp-ink;
$body-bg: $camp-neutral;
$body-color: $camp-text;

$border-color: $camp-paper;
$divider-color: #E1E3E5;

$red:          #d9534f;
$yellow:       #FFD950;
$green:        #02BC77;
$cyan:         #28c3d7;

$theme-colors: (
  "body":      $camp-neutral,
  "primary":   $camp-emerald,
  "secondary": $camp-text-secondary,
  "tertiary":  $camp-text-tertiary,
  "success":   $camp-emerald,
  "info":      $camp-navy,
  "warning":   $camp-gold,
  "danger":    $red,
  "dark":      $camp-ink,
  "light":     $camp-paper,
  "divider":   $divider-color,
);

$text-lightest: #fefefe;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1360px,
  xxl: 1500px,
  2xl: 1860px
);

$container-max-widths: (
  xl: 1300px,
);

$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-border-radius-lg: 0;

$custom-checkbox-indicator-border-radius: 0;
$custom-control-indicator-checked-color: $camp-emerald;
$custom-checkbox-indicator-bg-size: 100%;
$custom-checkbox-indicator-bg: "data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Crect width='4' height='4' stroke='%23FFFFFF' fill='none' stroke-width='1.25'/%3E%3C/svg%3E";

$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

$link-color: $camp-emerald;
$link-hover-color: darken($link-color, 8%);

$enable-responsive-font-sizes: true;
$font-size-base: 1.125rem;

$btn-font-size: calc($font-size-base * 16 / 18);

$input-btn-padding-y-sm: .375rem;
$input-btn-padding-x-sm: .75rem;
$input-btn-font-size-sm: calc($font-size-base * 14 / 18);

$h1-font-size: calc($font-size-base * 80 / 18);
$h2-font-size: calc($font-size-base * 60 / 18);
$h3-font-size: calc($font-size-base * 48 / 18);
$h4-font-size: calc($font-size-base * 36 / 18);
$h5-font-size: calc($font-size-base * 24 / 18);
$h6-font-size: calc($font-size-base * 21 / 18);

$headings-font-family: 'IBM Plex Sans', sans-serif;
$headings-font-weight: 700;

$badge-font-size: "";
