@use "sass:color";

@import "./_appwork/include-campv2";
@import "./_theme/common";
@import "./_theme/libs";
@import "./_theme/uikit";

#camp-navbar {
  font-family: $headings-font-family;
}

.eyebrow {
  font-size: 0.888rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 180%;
  letter-spacing: 0.15em;
}

#navbar_hero {
  @include media-breakpoint-up(lg) {
    .row.has-3-items {
      & div:nth-child(2n) {
        text-align: center;
      }
      & div:nth-child(3n) {
        text-align: right;
      }
    }
    .row.has-4-items {
      .col-lg {
        max-width: 50%;
        flex: 0 0 50%;
      }

      & div:nth-child(2n) {
        text-align: right;
      }
    }
    .row.has-5-items {
      .col-lg:nth-child(-n + 3) {
        max-width: 33.33333333%;
        flex: 0 0 33.33333333%;
      }

      & div:nth-child(2n),
      & div:nth-child(5) {
        text-align: center;
      }

      & div:nth-child(3) {
        text-align: right;
      }
    }
    .row.has-6-items {
      .col-lg {
        max-width: 33.3333333%;
        flex: 0 0 33.3333333%;
      }

      & div:nth-child(2),
      & div:nth-child(5) {
        text-align: center;
      }
      & div:nth-child(3),
      & div:nth-child(6) {
        text-align: right;
      }
    }
  }
}

.navbar-hero-icon {
  width: 54px;
}

.lead {
  line-height: 180%;
}

.nav-icon {
  width: 30px;
}

.home-icon {
  width: 30px;
}

#carouselVision .carousel-control-next {
  opacity: 1;

  a {
    margin-bottom: 2.5rem;
  }
}

a.hover-card {
  border: 3px solid transparent;

  @include hover-focus() {
    border: 3px solid $camp-gold;
  }
}

// We want text-dark links to be emerald on hover.
a.text-dark {
  @include hover-focus() {
    color: $camp-emerald-hover !important;
  }
}

@include appwork-common-theme($primary-color);
@include appwork-custom-control-theme($camp-emerald, null);
@include appwork-libs-theme($primary-color);
@include appwork-uikit-theme($primary-color);
@include appwork-navbar-variant('.bg-navbar-theme', $primary-color);

@include appwork-button-outline-variant('.btn-primary', $camp-emerald, $camp-emerald-hover, $camp-text, #FFF);
@include appwork-button-outline-variant('.btn-success', $camp-emerald, $camp-emerald-hover, $camp-text, #FFF);
@include appwork-button-outline-variant('.btn-outline-primary', $camp-emerald, $camp-emerald-hover, $camp-text, #FFF);
@include appwork-button-outline-variant('.btn-outline-success', $camp-emerald, $camp-emerald-hover, $camp-text, #FFF);

.bg-dark {
  @include appwork-button-variant('.btn-primary', $camp-emerald-light, $camp-emerald-light-hover);
  @include appwork-button-variant('.btn-success', $camp-emerald-light, $camp-emerald-light-hover);
  @include appwork-button-outline-variant('.btn-outline-primary', $camp-emerald-light, $camp-emerald-light-hover, #FFF, #FFF);
  @include appwork-button-outline-variant('.btn-outline-success', $camp-emerald-light, $camp-emerald-light-hover, #FFF, #FFF);
  @include bg-variant('.bg-primary', $camp-emerald-light);
  @include bg-variant('.bg-success', $camp-emerald-light);
  @include text-emphasis-variant('.text-primary', $camp-emerald-light, true);
  @include text-emphasis-variant('.text-success', $camp-emerald-light, true);

  a.text-primary, a.text-success {
    @include hover-focus() {
      color: $camp-emerald-light-hover !important;
    }
  }
}

.text-h1 { @include font-size($h1-font-size); }
.text-h2 { @include font-size($h2-font-size); }
.text-h3 { @include font-size($h3-font-size); }
.text-h4 { @include font-size($h4-font-size); }
.text-h5 { @include font-size($h5-font-size); }
.text-h6 { @include font-size($h6-font-size); }

// Pagination

.pagination {
  justify-content: center;

  .page-item {
    .page-link,
    &:first-child .page-link,
    &:last-child .page-link {
      border-radius: 0;
    }

    &.active .page-link {
      color: $camp-text;
      background-color: $camp-paper;

      &:hover, &:focus {
        color: $camp-text;
        background-color: $camp-paper;
      }
    }

    .page-link {
      font-weight: bold;
      color: lighten($camp-text, 50%);;
      border: 0;

      &:hover, &:focus {
        color: $camp-text;
        background-color: $camp-paper;
      }
    }
  }
}

.custom-select.small {
  font-size: 80%;
}

// Footer

.footer-light {
  color: $camp-text-secondary;

  a {
    color: $camp-text-secondary;
  }
}

.footer-dark {
  color: $camp-neutral;

  a {
    color: $camp-neutral;
    @include hover-focus() {
      color: $text-muted;
    }
  }
}
